import {  useMediaQuery } from '@mui/material';
import React, {  } from 'react';

import * as classes from './howItWorkSection.module.scss';
import Mexpert from './Mexpert';
import ExpertFlow from '../UserFlow/Expertflow';

export default function RecruiterFlow({ className, style }) {
  const matches = useMediaQuery('(min-width:720px)');

  return (
    <div style={style} className={`${classes.hiwSection} ${className}`}>

      <div className={classes.howItWorkTabContent}>
        {matches ? <ExpertFlow /> : <Mexpert />}
      </div>
    </div>
  );
}
