import {  useMediaQuery } from '@mui/material';
import React, {  } from 'react';

import * as classes from './howItWorkSection.module.scss';
import Memployer from './Memployer';
import Employerfflow from '../UserFlow/Employerfflow';

export default function EmployerFlow({ className, style }) {
  const matches = useMediaQuery('(min-width:720px)');

  return (
    <div style={style} className={`${classes.hiwSection} ${className}`}>

      <div className={classes.howItWorkTabContent}>
        {matches ? <Employerfflow /> : <Memployer />}
      </div>
    </div>
  );
}
